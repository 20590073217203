import React, { useState, useEffect } from "react";
import axios from "axios";
import { IoArrowBackCircle } from "react-icons/io5";
import { Link } from "react-router-dom";

const Chats = () => {
  const [chatData, setChatData] = useState([]);

  // Fetch chat data from the backend
  const fetchChatData = async () => {
    try {
      const response = await axios.get(
        "https://nsc-back.onrender.com/api/chats"
      ); // Replace with your endpoint
      setChatData(response.data);
    } catch (error) {
      console.error(
        "Error fetching chat data:",
        error.response || error.message
      );
    }
  };

  useEffect(() => {
    fetchChatData();
  }, []);

  return (
    <div className="chat-table-container p-5">
      <Link to="/admin/dashboard" className="text-decoration-none">
        <button>
          <IoArrowBackCircle />
          Back
        </button>
      </Link>
      <h2 className="mt-4 mb-4">Chat History</h2>
      <table className="chat-table">
        <thead>
          <tr>
            <th>Created Date</th>
            <th>Question</th>
            <th>Answer</th>
          </tr>
        </thead>
        <tbody>
          {chatData && chatData.length > 0 ? (
            chatData.map((chat) => (
              <React.Fragment key={chat._id}>
                {chat?.conversation.map((c, index) => (
                  <tr key={`${chat._id}-${index}`}>
                    <td>{new Date(c.timestamp).toLocaleString()}</td>
                    <td>{c.user}</td>
                    <td>{c.bot}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan="3">No chat data available.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Chats;
