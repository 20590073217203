import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getContact,
  resetError,
} from "../../../../redux/features/contactSlice";
import { toast } from "react-toastify";
import "../admindash.css";
const SingleContact = () => {
  const { error, contact } = useSelector((state) => state.contact);
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    if (params?.id) {
      dispatch(getContact(params?.id));
    }
  }, [dispatch, params?.id]);

  useEffect(() => {
    error && toast.error(error);
    dispatch(resetError());
  }, [error, dispatch]);
  console.log(contact);

  return (
    <div className="inner_form">
      <div className="container pt-lg-5 pb-lg-5">
        <div className="create_form p-5">
          <h2 className="text-center">Message</h2>
          <div className="message">
            <p>Name: {contact.name}</p>
            <p>Email: {contact.email}</p>
            <p>Address: {contact.address}</p>
            <p>Contact Number: {contact.phone}</p>
            <p>
              Message: <br />
              {contact.message}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleContact;
