import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { FaRegMessage } from "react-icons/fa6";
import { IoIosSend } from "react-icons/io";

const Chat = () => {
  const [showChat, setShowChat] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  // Ref for the chat body to auto-scroll
  const chatBodyRef = useRef(null);

  const toggleChat = () => {
    setShowChat(!showChat);
  };

  const handleSend = async () => {
    if (!message) return; // Don't send if the message is empty
    const timestamp = new Date().toISOString(); // Add timestamp
    // Add the user's message to the messages array
    const userMessage = { text: message, sender: "user" };
    setMessages([...messages, userMessage]);

    try {
      const response = await axios.post(
        "https://chatbot.learninghammer.com/webhooks/rest/webhook",
        { message }
      );

      if (response?.data?.[0]?.text) {
        const botMessage = { text: response.data[0].text, sender: "bot" };
        setMessages((prevMessages) => [...prevMessages, botMessage]);

        if (botMessage) {
          await axios.post("https://nsc-back.onrender.com/api/create-chat", {
            conversation: {
              user: userMessage.text,
              bot: botMessage.text,
              timestamp,
            },
          });
        }
      }
    } catch (error) {
      console.error(
        "Error sending message:",
        error.response ? error.response.data : error.message
      );
    }

    // Clear input after sending
    setMessage("");
  };

  // Handle key down event to send message on Enter
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  // Scroll to the bottom of the chat whenever messages change
  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages, showChat]);

  return (
    <>
      {!showChat && (
        <div className="message_button" onClick={toggleChat}>
          <FaRegMessage className="micon" />
        </div>
      )}
      {showChat && (
        <div className="chat_block">
          <div className="chat_header">
            <h4>Chat With AI</h4>
            <button onClick={toggleChat} className="close_msg">
              X
            </button>
          </div>
          <div className="chat_body" ref={chatBodyRef}>
            {messages && messages.length > 0 ? (
              messages.map((msg, index) => (
                <div
                  key={index}
                  className={`chat_message ${
                    msg.sender === "user" ? "user_message" : "bot_message"
                  }`}
                >
                  {msg.text}
                </div>
              ))
            ) : (
              <p>Start your conversation...</p>
            )}
          </div>
          <div className="chat_input">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={handleKeyDown} // Add key down event listener
              placeholder="Type a message..."
            />
            <button onClick={handleSend}>
              <IoIosSend />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Chat;
