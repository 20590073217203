import React, { useEffect, useState } from "react";
import "./admindash.css";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getContacts } from "../../../redux/features/contactSlice";
import { getApplications } from "../../../redux/features/applicationSlice";

const AdminNav = () => {
  const { contacts } = useSelector((state) => state.contact);
  const { applications } = useSelector((state) => state.application);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContacts());
    dispatch(getApplications());
  }, [dispatch]);

  const [count, setCount] = useState(null);
  const [acount, setACount] = useState(null);

  useEffect(() => {
    const countUnviewedContacts = () => {
      return contacts.filter((contact) => !contact.isView).length;
    };
    const countUnviewedApplication = () => {
      return applications.filter((application) => !application.isView).length;
    };
    setCount(countUnviewedContacts());
    setACount(countUnviewedApplication());
  }, [contacts, applications]);

  return (
    <div className="dash_link">
      <p className="logo">
        <span>NS</span>College
      </p>
      <NavLink to="/admin/dashboard">&nbsp;&nbsp;Dashboard</NavLink>
      <NavLink to="/admin/banners">&nbsp;&nbsp;Banners</NavLink>
      {/* <NavLink to="/admin/students">&nbsp;&nbsp;Students</NavLink>
      <NavLink to="/admin/teachers">&nbsp;&nbsp;Teachers</NavLink>
      <NavLink to="/admin/faculties">&nbsp;&nbsp;Faculty</NavLink> */}
      <NavLink to="/admin/abouts">&nbsp;&nbsp;About</NavLink>
      <NavLink to="/admin/admission">&nbsp;&nbsp;Admission Content</NavLink>
      <NavLink to="/admin/scholarship">&nbsp;&nbsp;Scholarship Content</NavLink>
      <NavLink to="/admin/pmessages">&nbsp;&nbsp;Principle Message</NavLink>
      <NavLink to="/admin/news-and-events">&nbsp;&nbsp;News & Events</NavLink>
      {/* <NavLink to="/admin/faqs">&nbsp;&nbsp;Faq</NavLink>
     
      <NavLink to="/admin/subjects">&nbsp;&nbsp;Subjects</NavLink>
      <NavLink to="/admin/notes">&nbsp;&nbsp;Notes</NavLink>
      <NavLink to="/admin/questions">&nbsp;&nbsp;Questions</NavLink> */}
      {/* <NavLink to="/admin/almuni">&nbsp;&nbsp;Almuni</NavLink> */}
      <NavLink to="/admin/semesters">&nbsp;&nbsp;Semester</NavLink>
      <NavLink to="/admin/testimonials">&nbsp;&nbsp;Testimonials</NavLink>
      <NavLink to="/admin/gallery">&nbsp;&nbsp;Gallerys</NavLink>
      <NavLink to="/admin/notices">&nbsp;&nbsp;Notices</NavLink>
      <NavLink to="/admin/partners">&nbsp;&nbsp;Partners</NavLink>
      <NavLink to="/admin/applications">
        &nbsp;&nbsp;Applicatioins{" "}
        <sup style={{ fontSize: "18px" }}>{acount === 0 ? "" : acount}</sup>
      </NavLink>
      <NavLink to="/admin/contacts">
        &nbsp;&nbsp;Contacts{" "}
        <sup style={{ fontSize: "18px" }}>{count === 0 ? "" : count}</sup>
      </NavLink>
      <NavLink to="/admin/chats">&nbsp;&nbsp;Chatbot Questions</NavLink>
    </div>
  );
};

export default AdminNav;
